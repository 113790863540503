import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";
import { wpImage, wpImageFluid, wpImageOnlySvg } from "./fragments";

const wireswarmPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_Wireswarm {
                  templateName
                  wireswarmRichContent {
                    #   SECTION: Hero
                    hero {
                      header
                      text
                      buttons {
                        button {
                          variant
                          link {
                            target
                            title
                            url
                          }
                        }
                      }
                      logo {
                        ...wpImage
                      }
                      image {
                        altText
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(
                              width: 1800
                              placeholder: BLURRED
                              quality: 100
                            )
                          }
                          childSvg {
                            content {
                              data
                            }
                          }
                        }
                      }
                    }

                    # SECTION: Challenges
                    challenges {
                      imageSection {
                        text
                        title
                        slug
                        variant
                        image {
                          ...wpImageFluid
                        }
                      }
                    }

                    # SECTION: Product Mockup Image
                    productMockupImage {
                      imageSection {
                        text
                        title
                        slug
                        variant
                        image {
                          ...wpImageFluid
                        }
                      }
                    }

                    # SECTION: Digital Orchestration
                    digitalOrchestration {
                      imageSection {
                        text
                        title
                        slug
                        variant
                        image {
                          ...wpImageFluid
                        }
                      }
                    }

                    # SECTION: Features
                    features {
                      text
                      header
                      slug
                      content {
                        ... on WpTemplate_Wireswarm_Wireswarmrichcontent_Features_Content_Accordion {
                          fieldGroupName
                          header
                          content {
                            ... on WpTemplate_Wireswarm_Wireswarmrichcontent_Features_Content_Accordion_Content_Text {
                              fieldGroupName
                              header
                              text
                              link {
                                target
                                title
                                url
                              }
                            }
                            ... on WpTemplate_Wireswarm_Wireswarmrichcontent_Features_Content_Accordion_Content_List {
                              fieldGroupName
                              header
                              textAbove
                              textBelow
                            }
                          }
                        }
                        ... on WpTemplate_Wireswarm_Wireswarmrichcontent_Features_Content_List {
                          fieldGroupName
                          header
                          textAbove
                          textBelow
                        }
                        ... on WpTemplate_Wireswarm_Wireswarmrichcontent_Features_Content_Text {
                          fieldGroupName
                          header
                          text
                        }
                      }
                    }

                    # SECTION: Our Partners
                    ourPartners {
                      header
                      text
                      slug
                      logos {
                        image {
                          ...wpImage
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "wireswarmRichContent");
};

export { wireswarmPageTplData };
