import * as React from "react";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { wireswarmPageTplData } from "../data/page-tpl-wireswarm.data";

import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import Hero from "../componentsSections/Hero";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";
import ImageSection from "../components/ImageSection";
import GetInTouchButton from "../components/GetInTouchButton";
import AccordionSection from "../components/AccordionSection";
import ConnectivityLogos from "../components/ConnectivityLogos";

// Import images

const PageWiresWarm = ({ pageContext: { id } }) => {
    const seoPageData = getCurrentPageSeo(id);
    const pageData = wireswarmPageTplData(id);

    return (
        <>
            <Layout transparentNavbar={!!pageData.hero.image}>
                {/* SEO Metas */}
                <Seo post={seoPageData} />

                {/* Page head */}
                <Hero {...pageData.hero} textSize="big" />

                {/* Challenges Section */}
                <Section>
                    <ImageSection
                        oversizeContent
                        containerClassName="-top-spacer"
                        {...pageData.challenges}
                    />
                </Section>

                <Section>
                    <GetInTouchButton />
                </Section>

                {/* Product Mockup Image Section */}
                <Section className="-mobile-full">
                    <ImageSection {...pageData.productMockupImage} />
                </Section>

                <Section>
                    <GetInTouchButton />
                </Section>

                {/* Digital asset Orchestration Section */}
                <Section>
                    <ImageSection {...pageData.digitalOrchestration} />
                </Section>

                <Section>
                    <GetInTouchButton />
                </Section>

                {/* Features Section */}
                <Section>
                    <AccordionSection {...pageData.features} />
                </Section>

                <Section>
                    <GetInTouchButton />
                </Section>

                {/* Our Partners Section */}
                <Section>
                    <ConnectivityLogos
                        galleryClassName="-big"
                        containerClassName="pt-0"
                        {...pageData.ourPartners}
                    />
                </Section>

                {/* GetInTouch Section */}
                <Section className="-mobile-full">
                    <GetInTouch />
                </Section>

                {/* News and insights Section */}
                <Section>
                    <NewsAndInsights />
                </Section>
            </Layout>
        </>
    );
};

export default PageWiresWarm;
